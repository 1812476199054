import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function isValidURL(string) {
    if (!string) return null;

    let res = string.match(
        // eslint-disable-next-line no-useless-escape
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
}

const replacePath = path =>
    path
        .replace("https://admin.", "https://")
        .replace("https://tadmin.", "https://");

const createURL = path => {
    if (isValidURL(path)) {
        return replacePath(path);
    }

    if (typeof window === "undefined") {
        return null;
    }

    return document.location.href;
};

function Seo({ description, lang, meta, keywords, title, image, url, schema }) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription =
                    description || data.site.siteMetadata.description;

                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={title}
                        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                        meta={[
                            {
                                name: "description",
                                content: metaDescription,
                            },
                            {
                                property: "og:title",
                                content: title,
                            },
                            {
                                property: "og:image",
                                content: image && createURL(image),
                            },
                            {
                                property: "og:description",
                                content: metaDescription,
                            },
                            {
                                property: "og:type",
                                content: "website",
                            },
                            {
                                property: "og:url",
                                content: createURL(url),
                            },
                            {
                                name: "twitter:card",
                                content: "summary",
                            },
                            {
                                name: "twitter:creator",
                                content: data.site.siteMetadata.author,
                            },
                            {
                                name: "twitter:title",
                                content: title,
                            },
                            {
                                name: "twitter:url",
                                content: createURL(url),
                            },
                            {
                                name: "twitter:description",
                                content: metaDescription,
                            },
                            {
                                name: "twitter:image",
                                content: image && createURL(image),
                            },
                        ]
                            .concat(
                                keywords.length > 0
                                    ? {
                                          name: "keywords",
                                          content: keywords.join(", "),
                                      }
                                    : []
                            )
                            .concat(meta)}
                    >
                        {schema && (
                            <script type="application/ld+json">{schema}</script>
                        )}
                    </Helmet>
                );
            }}
        />
    );
}

Seo.defaultProps = {
    lang: "en",
    meta: [],
    keywords: [],
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
};

export default Seo;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                wordPressUrl
            }
        }
    }
`;
